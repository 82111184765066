<style scoped>
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <div class="c-panel">
      <div class="c-title">安全设置</div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="操作密码设置" name="first">
          <el-row v-show="isBind == false">
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <el-form ref="form" label-width="150px">
                  <el-form-item label="操作密码:">
                    <el-input size="small" v-model="data.passWord" type="password" style="width: 350px"></el-input>
                  </el-form-item>
                  <el-form-item label="确认操作密码:">
                    <el-input size="small" v-model="data.confirmPassword" type="password" style="width: 350px"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button size="small" type="primary" @click="setOptPwsd">确定</el-button>
                    <!-- <el-button type="danger">清空缓存</el-button> -->
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
          <el-result icon="success" title="已设置操作密码" v-show="isBind == true">
          </el-result>
        </el-tab-pane>
        <el-tab-pane label="修改操作密码" name="second">
          <el-row v-show="isBind == true">
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <el-form ref="form" label-width="150px">
                  <el-form-item label="原操作密码:">
                    <el-input size="small" v-model="data.originalPassWord" type="password" style="width: 350px"></el-input>
                  </el-form-item>
                  <el-form-item label="新操作密码:">
                    <el-input size="small" v-model="data.passWord" type="password" style="width: 350px"></el-input>
                  </el-form-item>
                  <el-form-item label="确认新操作密码:">
                    <el-input size="small" v-model="data.confirmPassword" type="password" style="width: 350px"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button size="small" type="primary" @click="restOptPwsd">修改</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
          <el-result icon="error" title="未设置操作密码" v-show="isBind == false">
          </el-result>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        originalPassWord: null,
        passWord: null,
        confirmPassword: null,
      },
      isBind: true,
      activeName: "first",
    };
  },
  methods: {
    fucisBind: function () {
      var defaultCfg2 = {
        type: "get", //是否是请求体请求
      };
      this.sa.ajax('/v1/merchant/isBindOptPwsd', function (res) {
        if (res.data == '未设置操作密码') {
          this.isBind = false;
        } else {
          this.isBind = true;
        }
      }.bind(this), defaultCfg2)
    },
    setOptPwsd: function () {
      var defaultCfg2 = {
        isBody: true, //是否是请求体请求
      };
      this.sa.ajax('/v1/merchant/setOptPwsd', this.data, function (res) {
        if (res.code == 400) {
          this.isBind = false;
          this.sa.error2(res.msg);
        } else {
          this.isBind = true;
          this.clearData()
        }
      }.bind(this), defaultCfg2)
    },
    restOptPwsd: function () {
      var defaultCfg2 = {
        isBody: true, //是否是请求体请求
      };
      this.sa.ajax('/v1/merchant/restOptPwsd', this.data, function (res) {
        if (res.code == 400) {
          this.isBind = false;
          this.sa.error2(res.msg);
        } else {
          this.isBind = true;
          this.sa.ok("修改成功");
          this.clearData()
        }
      }.bind(this), defaultCfg2)
    },
    clearData() {
      this.data.passWord = null;
      this.data.confirmPassword = null;
      this.data.originalPassWord = null;
    }
  },
  created() {
    this.fucisBind();
  },
};
</script>
